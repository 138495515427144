import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/market',
    name: 'market',
    component: () => import('../views/market/index.vue')
  },
  {
    path: '/ai',
    name: 'ai',
    component: () => import('../views/ai/index.vue')
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('../views/user/team.vue')
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('../views/wallet/index.vue')
  },
  {
    path: '/pledge',
    name: 'pledge',
    component: () => import('../views/pledge/index.vue')
  },
  {
    path: '/pledge',
    name: 'pledge',
    component: () => import('../views/pledge/index.vue')
  },
  {
    path: '/pledgeRecode',
    name: 'pledgeRecode',
    component: () => import('../views/pledge/pledgeRecode.vue')
  },
  {
    path: '/bill',
    name: 'bill',
    component: () => import('../views/wallet/bill.vue')
  },
  {
    path: '/bonus',
    name: 'bonus',
    component: () => import('../views/wallet/bonus.vue')
  },
  {
    path: '/chongzhiRecord',
    name: 'chongzhiRecord',
    component: () => import('../views/wallet/chongzhiRecord.vue')
  },
  {
    path: '/tixianRecord',
    name: 'tixianRecord',
    component: () => import('../views/wallet/tixianRecord.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
